import SlimSelect from 'slim-select';
import { checkParentClass } from '../../utilities/js/helper';

class Select {
    constructor (element) {
        this.$select = element;

        this.initialize();
        this.selectElement = null;
    }

    initialize () {
        if (this.$select) {
            this.checkOpenDirection();
        }
    }

    checkOpenDirection () {
        const $parentContainer = this.$select.closest('.is--mobile');
        let openDirection = 'down';
        if ($parentContainer) {
            openDirection = 'up';
        }

        if (!this.$select.classList.contains('technical-details-select')) {
            this.buildCustomSelect(this.$select, openDirection);
        }
    }

    buildCustomSelect ($select, $openDirection) {
        const $formField = this.$select.parentNode;
        if ($select.classList.contains('lang-select')) {
            this.selectElement = new SlimSelect({
                select: $select,
                showSearch: false,
                showContent: $openDirection,
                onChange: (info) => {
                    const newPath = info.value;
                    location.href = newPath;
                }
            });
        } else if (checkParentClass(this.$select, 'default-form')) {
            this.selectElement = new SlimSelect({
                select: $select,
                showSearch: false,
                showContent: $openDirection,
                afterOpen: () => {
                    $formField.classList.add('is--open');
                },
                afterClose: () => {
                    $formField.classList.remove('is--open');
                    if ($formField.id === 'contact_phone') {
                        const $placeholder = $formField.querySelector('.placeholder');
                        this.setPhonePlaceholder($placeholder);
                    }
                },
                onChange: (info) => {
                    const value = info.value;
                    if (value !== '') {
                        $formField.classList.add('is--filled');
                    } else {
                        $formField.classList.remove('is--filled');
                    }
                }
            });
            if ($formField.id === 'contact_phone') {
                const $placeholder = $formField.querySelector('.placeholder');
                this.setPhonePlaceholder($placeholder);
            }

            if (this.selectElement.selected()) {
                $formField.classList.add('is--filled');
            }
        } else {
            this.selectElement = new SlimSelect({
                select: $select,
                showSearch: false,
                showContent: $openDirection
            });
        }
    }

    setPhonePlaceholder (placeholder) {
        const placeholderText = placeholder.innerHTML;
        const onlyNumbers = placeholderText.replace(/\D/g, '');
        const newPlaceholder = '+' + onlyNumbers;
        placeholder.innerHTML = newPlaceholder;
    }
}

export { Select };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $selects = $context.querySelectorAll('select');
        if ($selects.length > 0) {
            for (let i = 0; i < $selects.length; i++) {
                const selectAPI = new Select($selects[i]);
                $selects[i].API = selectAPI;
            }
        }
    }
});
